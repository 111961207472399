import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useCheckPaymentStatus } from '../../api/account';

const TrialNotification = () => {
  const [upgradeLink, setUpgradeLink] = useState('');
  const { mutate } = useCheckPaymentStatus();

  useEffect(() => {
    const storedBackUrl = localStorage.getItem('pc_back_url');
    if (storedBackUrl) {
      const urlParams = new URLSearchParams(decodeURIComponent(storedBackUrl));
      const instance = urlParams.get('instance');
      if (instance) {
        mutate(instance, {
          onSuccess: (response) => {
            const { data } = response;
            if (data.upgrade_link) {
              setUpgradeLink(data.upgrade_link);
            }
          },
          onError: (error) => {
            console.error('Error checking payment status:', error);
          }
        });
      }
    }
  }, [mutate]);

  const handleStartTrial = () => {
    if (upgradeLink) {
      window.open(upgradeLink, '_blank');
    }
  };

  if (!upgradeLink) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#f8d7da', color: '#721c24', padding: '1rem', textAlign: 'center' }}>
      <span>Start your 14-day free trial to use this app. </span>
      <Button variant="primary" onClick={handleStartTrial} style={{ marginLeft: '1rem' }}>Start Trial</Button>
    </div>
  );
};

export default TrialNotification;
